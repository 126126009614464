import * as React from "react";
import { graphql } from 'gatsby';
import MainLayout from '../components/mainLayout';
import SEO from '../components/seo';
import styledComponents from 'styled-components';

const LastUpdated = styledComponents.p`
  opacity: 0.75;
`;

// markup
const ContentPage = ({ data }) => {
  const shouldShowJumpMark = data.markdownRemark.html.includes('<div id="last-update">');

  return (
    <main>
      <MainLayout>
        <SEO title={data.markdownRemark.frontmatter.title} description={data.markdownRemark.excerpt} />

        <h1>{data.markdownRemark.frontmatter.title}</h1>
        <LastUpdated>
          zuletzt aktualisiert: {data.markdownRemark.frontmatter.lastUpdate} 

          {shouldShowJumpMark && (
            <>
              &nbsp;
              <a href="#last-update">zum neuesten Inhalt springen</a>
            </>
          )}
        </LastUpdated>

        <div className="text-content" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </MainLayout>
    </main>
  )
}

export default ContentPage

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt
      frontmatter {
        title
        slug
        lastUpdate(formatString: "DD.MM.YYYY")
      }
    }
  }
`
